<template>
  <div>
    <TTableAdvance
      :items="list"
      :fields="fields"
      store="product.product_categories"
      resource=""
      creatable
      @click-clear-filter="clearFilter"
      @click-create="showCreateCategory = true"
    >
      <template #append-actions="{ item }">
        <CCol class="d-flex px-2" col="12">
          <TButtonEdit @click="editItem(item)" />
          <TButtonDelete @click="removeCate(item.id)" />
        </CCol>
      </template>
      <template #name="{ item }">
        <td>
          <TMessageText :value="item.name" noTranslate />
        </td>
      </template>
      <template #parent="{ item }">
        <td>
          <TMessageText
            v-if="item.parent"
            :value="item.parent.name"
            noTranslate
          />
        </td>
      </template>
      <template #name-filter>
        <TInputText
          :value.sync="filter.name"
          @update:value="filterChange"
          placeholder="Category"
        />
      </template>
      <template #parent-filter>
        <SSelectProductCategory
          :value.sync="filter.parent_id"
          @change="filterChange"
        />
      </template>
    </TTableAdvance>
    <CategoryModal
      @updated="fetchCategories()"
      :show.sync="showCreateCategory"
    />
    <CategoryModal
      @updated="fetchCategories()"
      :show.sync="showEditCategory"
      :category="detail"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CategoryModal from "./components/CategoryModal.vue";

export default {
  components: { CategoryModal },
  data() {
    return {
      fields: [
        { key: "_", _style: "width: 40px" },
        { key: "id", label: "Id", style: "min-width: 300px" },
        { key: "name", label: "Category", _style: "width: 35%" },
        {
          key: "parent",
          label: "Parent category",
          _classes: "text-nowrap",
          _style: "width: 35%",
        },
      ],
      filter: {},
      showCreateCategory: false,
      showEditCategory: false,
    };
  },
  created() {
    this.filterChange();
  },
  computed: {
    ...mapGetters({
      list: "product.product_categories.list",
      detail: "product.product_categories.detail",
    }),
  },
  methods: {
    removeCate(id) {
      this.$store.dispatch("product.product_categories.delete", id);
    },
    fetchCategories() {
      this.$store.dispatch("product.product_categories.apply-query", {});
    },
    filterChange() {
      const filter = this.lodash.mapKeys(this.filter, function (value, key) {
        return `filter[${key}]`;
      });
      const filterFields = this.lodash.pickBy(filter);
      this.$store.dispatch(
        "product.product_categories.apply-query",
        filterFields
      );
    },
    clearFilter() {
      this.filter = {};
      this.filterChange();
    },
    editItem(item) {
      this.$store.commit("product.product_categories.select", item.id);
      this.showEditCategory = true;
    },
  },
};
</script>
